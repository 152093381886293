import GETTER_TYPES from "./getter-types";

export default {
    namespaced: true,
    state: {
        en: {
            'entities.delivery_excluded_date.singular': "Excluded Date",
            'entities.delivery_excluded_date.plural': "Excluded Dates",
            'entities.delivery_excluded_date.fields.name.label': 'Name',
            'entities.delivery_excluded_date.fields.name.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.delivery_excluded_date.fields.date.label': 'Date',
            'entities.delivery_excluded_date.fields.date.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.delivery_excluded_date.fields.exclusion_type.label': 'Exclusion Type',
            'entities.delivery_excluded_date.fields.exclusion_type.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.delivery_excluded_date.fields.active.label': 'Active',
            'entities.delivery_excluded_date.fields.active.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',

            'entities.delivery_charge.singular': "Delivery Charge",
            'entities.delivery_charge.plural': "Delivery Charges",
            'entities.delivery_charge.fields.admin_title.label': 'Admin Title',
            'entities.delivery_charge.fields.admin_title.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.delivery_charge.fields.title.label': 'Title',
            'entities.delivery_charge.fields.title.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.delivery_charge.fields.description.label': 'Description',
            'entities.delivery_charge.fields.description.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.delivery_charge.fields.service_code.label': 'Service Code',
            'entities.delivery_charge.fields.service_code.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.delivery_charge.fields.delivery_type.label': 'Delivery Type',
            'entities.delivery_charge.fields.delivery_type.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.delivery_charge.fields.number_working_days_active.label': 'Number Working Days Switch',
            'entities.delivery_charge.fields.number_working_days_active.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.delivery_charge.fields.number_working_days.label': 'Number Working Days',
            'entities.delivery_charge.fields.number_working_days.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.delivery_charge.fields.max_number_working_days.label': 'Max Number Working Days',
            'entities.delivery_charge.fields.max_number_working_days.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.delivery_charge.fields.days_of_week.label': 'Days of Week',
            'entities.delivery_charge.fields.days_of_week.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.delivery_charge.fields.price_repeater.label': 'By Price',
            'entities.delivery_charge.fields.price_repeater.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.delivery_charge.fields.price.label': 'Price',
            'entities.delivery_charge.fields.price.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.delivery_charge.fields.product_type.label': 'Must not contain the following... (Off will mean it Must)',
            'entities.delivery_charge.fields.product_type.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.delivery_charge.fields.product_groupings.label': 'Product Groupings',
            'entities.delivery_charge.fields.product_groupings.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.delivery_charge.fields.postcode_type.label': 'Must not contain the following... (Off will mean it Must)',
            'entities.delivery_charge.fields.postcode_type.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.delivery_charge.fields.postcode_groupings.label': 'Postcode Groupings',
            'entities.delivery_charge.fields.postcode_groupings.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.delivery_charge.fields.excluded_charges.label': 'Delivery Charges to exclude if this one is met',
            'entities.delivery_charge.fields.excluded_charges.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.delivery_charge.fields.excluded_dates.label': 'Excluded Dates',
            'entities.delivery_charge.fields.excluded_dates.hint': 'No dates selected here means this charge will use all excluded dates.',

            'entities.delivery_rate_request.singular': "Delivery Rate Request",
            'entities.delivery_rate_request.plural': "Delivery Rate Requests",
            'entities.delivery_rate_request.fields.title.label': 'Title',
            'entities.delivery_rate_request.fields.title.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.delivery_rate_request.fields.request_date.label': 'Request Date',
            'entities.delivery_rate_request.fields.request_date.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.delivery_rate_request.fields.destination_postcode.label': 'Destination Postcode',
            'entities.delivery_rate_request.fields.destination_postcode.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.delivery_rate_request.fields.cart_items.label': 'Cart Items',
            'entities.delivery_rate_request.fields.cart_items.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',

            'entities.stock-notice.singular': "Stock Notice",
            'entities.stock-notice.plural': "Stock Notices",
            'entities.stock-notice.fields.type.label': 'Type',
            'entities.stock-notice.fields.type.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.stock-notice.fields.admin_title.label': 'Admin Title',
            'entities.stock-notice.fields.admin_title.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.stock-notice.fields.title.label': 'Title',
            'entities.stock-notice.fields.title.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.stock-notice.fields.description.label': 'Description',
            'entities.stock-notice.fields.description.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.stock-notice.fields.skus.label': 'SKUs',
            'entities.stock-notice.fields.skus.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.stock-notice.fields.date.label': 'Date for Stock Notice to expire',
            'entities.stock-notice.fields.date.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',

            'entities.postcode_grouping.singular': "Postcode Grouping",
            'entities.postcode_grouping.plural': "Postcode Groupings",
            'entities.postcode_grouping.fields.title.label': 'Title',
            'entities.postcode_grouping.fields.title.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.postcode_grouping.fields.postcodes.label': 'Postcodes',
            'entities.postcode_grouping.fields.postcodes.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',

            'entities.product_grouping.singular': "Product Grouping",
            'entities.product_grouping.plural': "Product Groupings",
            'entities.product_grouping.fields.title.label': 'Title',
            'entities.product_grouping.fields.title.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.product_grouping.fields.products.label': 'Products',
            'entities.product_grouping.fields.products.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.product_grouping.fields.skus.label': 'SKUs',
            'entities.product_grouping.fields.skus.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.product_grouping.fields.delivery_price.label': 'Delivery Price',
            'entities.product_grouping.fields.delivery_price.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',

            'entities.build_service.singular': "Build Service",
            'entities.build_service.plural': "Build Services",
            'entities.build_service.fields.title.label': 'Title',
            'entities.build_service.fields.title.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.build_service.fields.price.label': 'Price',
            'entities.build_service.fields.price.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.build_service.fields.postcode_groupings.label': 'Postcode Groupings to Exclude',
            'entities.build_service.fields.postcode_groupings.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
            'entities.build_service.fields.skus.label': 'SKUs',
            'entities.build_service.fields.skus.hint': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh turpis, venenatis in interdum et, ornare vel arcu. Maecenas nec nulla feugiat, accumsan dolor vel, pretium orci.',
        }
    },
    getters: {
        /** @todo: Detect browser locale. Check if string for that lang exists. If not, fallback to`en` */
        [GETTER_TYPES.GET_STRING.NAME]: (state) => (translationKey) => {
            return state.en[translationKey] ?? null;
        }
    },
};
